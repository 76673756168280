import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu>;
  public isSubMenu: boolean;
  constructor(public menuService:MenuService) { }

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalMenuItems();

    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId); 
  }
  // menu2enter(e)
  // {
  //   this.isSubMenu=true;
  // }
  // menu2leave(e)
  // {
  //   console.log(this);
  //   this.isSubMenu=false;
    
    
  // }
  mouseEnter(e){
    //console.log(e)
    if(e=='subMenu') {
      this.isSubMenu = true;
    }
    e.openMenu();
    // e.click();
    // this.menuService.toggleMenuItem(menuId);
    // this.menuService.closeOtherSubMenus(this.menuService.getHorizontalMenuItems(), menuId);
  }
  
  // mouseLeave(e){
  //   console.log(this.isSubMenu)
  //   setTimeout(() => 
  //   {

  //      if(!this.isSubMenu)
  //      {
  //       this.isSubMenu=false;
       
  //      }
      
  //      e.closeMenu();
   
  //   },
  //   1500);
    
  //   // e.click();
  //   // this.menuService.toggleMenuItem(menuId);
  //   // this.menuService.closeOtherSubMenus(this.menuService.getHorizontalMenuItems(), menuId);    
  // }
  


}
