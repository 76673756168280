import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0),

    new Menu (2, 'NAV.ABOUT_US', '/about/all', null, null, true, 0),   
    new Menu (20, 'OUR VISION', '/about/our-vision', null, null, false, 2),   
    new Menu (21, 'OUR MISSION', '/about/our-mission', null, null, false, 2),   
    new Menu (22, 'TESTIMONIAL', '/about/testimonials', null, null, false, 2),

    new Menu (500, 'NAV.PROPERTIES', '/properties', null, null, true, 0), 
    new Menu (501, 'Exclusive Listings', '/our-properties/exclusiveListings', null, null, false, 500), 
    new Menu (501, 'Our Listings', '/our-properties/ourListings', null, null, false, 500), 
    new Menu (501, 'MLS listings', '/properties', null, null, false, 500), 
    new Menu (501, 'Buying a Property', '/buying-property/buying', null, null, false, 500), 
    new Menu (501, 'Selling your Property', '/buying-property/selling', null, null, false, 500), 

    new Menu (40, 'NAV.PAGES', null, null, null, false, 0),
    
    new Menu (41, 'OUR TEAM', '/our-teams', null, null, true, 0), 
    new Menu (411, 'Team Realty Delta', '/agents', null, null, false, 41), 
    new Menu (412, 'New Agent Join our Team', '/faq-content/newAgentJoin', null, null, false, 41), 
    new Menu (413, 'Experienced Agent Excel in your career Join our Team', '/faq-content/experiencedAgent', null, null, false, 41), 

    new Menu (300, 'MORTGAGE', '/mortgage/all/', null, null, true, 0), 
    // new Menu (301, 'MORTGAGE', '/mortgage/all/', null, null, false, 300), 
    // new Menu (301, 'Pre-Approvals', '/mortgage/pre-approvals/', null, null, false, 300), 
    // new Menu (302, 'New Immigrants', '/mortgage/new-immigrants', null, null, false, 300), 
    // new Menu (303, 'Self-EmployedGAGE', '/mortgage/self-employed', null, null, false, 300), 
    // new Menu (304, 'Private Mortgage', '/mortgage/private-mortgage', null, null, false, 300), 
    // new Menu (305, 'Cash Back', '/mortgage/cash-back', null, null, false, 300), 
    // new Menu (306, 'Debt Consolidation', '/mortgage/debt-consolidation', null, null, false, 300), 
    // new Menu (307, 'Renewal', '/mortgage/renewal', null, null, false, 300), 
    // new Menu (308, 'Refinancing', '/mortgage/refinancing', null, null, false, 300), 
    // new Menu (309, 'Home Equity Line Of Credit', '/mortgage/home-equity', null, null, false, 300), 
    new Menu (310, 'MORTGAGE CALCULATOR', '/mcalculator', null, null, false, 300), 
    new Menu (311, 'MORTAGE TEAM', '/our-team', null, null, false, 300), 

    new Menu (42, 'NAV.AGENT', '/agents/1', null, null, false, 40),
    new Menu (44, 'REGISTER', '/register', null, null, false, 40), 

    new Menu (45, 'Expert Advice & info', '/expert-advice/all/', null, null, true, 0),
    new Menu (451, 'Buyers Guide', '/expert-advice/buyers-guide', null, null, false, 45),
    new Menu (452, 'Sellers Guide', '/expert-advice/sellers-guide', null, null, false, 45),
    new Menu (453, 'Home Maintenance tips', '/expert-advice/home-maintenance-tips', null, null, false, 45),
    new Menu (454, 'FAQs', '/faq', null, null, false, 45),

    new Menu (46, 'NAV.PRICING', '/pricing', null, null, false, 40), 
    new Menu (47, 'NAV.TERMS_CONDITIONS', '/terms-conditions', null, null, false, 40), 
    new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    new Menu (50, '404 Page', '/404', null, null, false, 40),  
    new Menu (60, 'NAV.CONTACT', '/contact', null, null, false, 0),  

    new Menu (61, 'Blog', null, 'https://realtor.logocorps.dev/blog', null, false, 0),
    new Menu (62, 'Login', null, 'https://realtoradmin.logocorps.dev:3100/login', null, false, 0), 

    new Menu (140, 'Others', null, null, null, true, 40),
    new Menu (141, 'NAV.EXTERNAL_LINK', null, 'http://themeseason.com', '_blank', false, 140), 
    new Menu (142, 'Menu item', null, 'http://themeseason.com', '_blank', false, 140),
    new Menu (143, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    new Menu (144, 'Menu item', null,'http://themeseason.com', '_blank', false, 140)    
]

export const verticalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0),

    new Menu (2, 'NAV.ABOUT_US', '/about/all', null, null, true, 0),   
    new Menu (20, 'OUR VISION', '/about/our-vision', null, null, false, 2),   
    new Menu (21, 'OUR MISSION', '/about/our-mission', null, null, false, 2),   
    new Menu (22, 'TESTIMONIAL', '/about/testimonials', null, null, false, 2),

    new Menu (500, 'NAV.PROPERTIES', '/properties', null, null, true, 0), 
    new Menu (501, 'Exclusive Listings', '/properties', null, null, false, 500), 
    new Menu (501, 'Our Listings', '/properties', null, null, false, 500), 
    new Menu (501, 'MLS listings', '/properties', null, null, false, 500), 
    new Menu (501, 'Buying a Property', '/properties', null, null, false, 500), 
    new Menu (501, 'Selling your Property', '/properties', null, null, false, 500), 
    new Menu (40, 'NAV.PAGES', null, null, null, false, 0),
    
    new Menu (41, 'OUR TEAM', '/our-teams', null, null, true, 0), 
    new Menu (411, 'Team Realty Delta', '/agents', null, null, false, 41), 
    new Menu (412, 'New Agent Join our Team', '/faq-content/newAgentJoin', null, null, false, 41), 
    new Menu (413, 'Experienced Agent Excel in your career Join our Team', '/faq-content/experiencedAgent', null, null, false, 41), 

    new Menu (300, 'MORTGAGE', '/mortgage/all/', null, null, true, 0), 
    // new Menu (301, 'MORTGAGE', '/mortgage/all/', null, null, false, 300), 
    // new Menu (301, 'Pre-Approvals', '/mortgage/pre-approvals/', null, null, false, 300), 
    // new Menu (302, 'New Immigrants', '/mortgage/new-immigrants', null, null, false, 300), 
    // new Menu (303, 'Self-EmployedGAGE', '/mortgage/self-employed', null, null, false, 300), 
    // new Menu (304, 'Private Mortgage', '/mortgage/private-mortgage', null, null, false, 300), 
    // new Menu (305, 'Cash Back', '/mortgage/cash-back', null, null, false, 300), 
    // new Menu (306, 'Debt Consolidation', '/mortgage/debt-consolidation', null, null, false, 300), 
    // new Menu (307, 'Renewal', '/mortgage/renewal', null, null, false, 300), 
    // new Menu (308, 'Refinancing', '/mortgage/refinancing', null, null, false, 300), 
    // new Menu (309, 'Home Equity Line Of Credit', '/mortgage/home-equity', null, null, false, 300), 
    new Menu (310, 'MORTGAGE CALCULATOR', '/mcalculator', null, null, false, 300), 
    new Menu (311, 'MORTAGE TEAM', '/our-team', null, null, false, 300), 

    new Menu (42, 'NAV.AGENT', '/agents/1', null, null, false, 40),
    new Menu (44, 'REGISTER', '/register', null, null, false, 40), 

    new Menu (45, 'Expert Advice & info', '/expert-advice/all/', null, null, true, 0),
    new Menu (451, 'Buyers Guide', '/expert-advice/buyers-guide', null, null, false, 45),
    new Menu (452, 'Sellers Guide', '/expert-advice/sellers-guide', null, null, false, 45),
    new Menu (453, 'Home Maintenance tips', '/expert-advice/home-maintenance-tips', null, null, false, 45),
    new Menu (454, 'FAQs', '/faq', null, null, false, 45),

    new Menu (46, 'NAV.PRICING', '/pricing', null, null, false, 40), 
    new Menu (47, 'NAV.TERMS_CONDITIONS', '/terms-conditions', null, null, false, 40), 
    new Menu (48, 'Landing', '/landing', null, null, false, 40),  
    new Menu (50, '404 Page', '/404', null, null, false, 40),  
    new Menu (60, 'NAV.CONTACT', '/contact', null, null, false, 0),  

    new Menu (61, 'Blog', null, 'https://realtor.logocorps.dev/blog', null, false, 0),
    new Menu (62, 'Login', null, 'https://realtoradmin.logocorps.dev:3100/login', null, false, 0), 

    new Menu (140, 'Others', null, null, null, true, 40),
    new Menu (141, 'NAV.EXTERNAL_LINK', null, 'http://themeseason.com', '_blank', false, 140), 
    new Menu (142, 'Menu item', null, 'http://themeseason.com', '_blank', false, 140),
    new Menu (143, 'Menu item', null,'http://themeseason.com', '_blank', false, 140),
    new Menu (144, 'Menu item', null,'http://themeseason.com', '_blank', false, 140)    
]




